<template>
<div>

  <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Edit Attendance Status">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">

            
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Title"
                  class="required"
                >
                  <b-form-input
                    placeholder="3 to 50 characters"
                    v-model="form.title"
                  />
                </b-form-group>
              </b-col>

              
            </b-row>

            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Prefix"
                  class="required"
                >
                  <b-form-input
                    placeholder="2 to 10 characters"
                    v-model="form.short"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            
            
            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name: 'attendance-status'})"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  
</div>
</template>

<script>

import {
  BBreadcrumb, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';



export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    BBreadcrumb
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      
      error_message:null,
      showDismissibleAlert:false,

      form :{
        title : '',
        short:'',
        id:''
        
      }
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/update-attendance-status'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name: 'attendance-status'});
                });
                
            }
        });
      
    },

    jobTitleDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id
           },
           api: '/api/attendance-status-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data = this.$store.getters.getResults.data;
                
                this.form.title = data.title;
                this.form.short = data.short;
                this.form.id = data._id;
                return this.form;
            }
        });
    },

    breadCrumb(){
      var item = [{
        to:{name:'admin-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'DataBase',
      },{
        to:{name:'attendance-status'},
        text: 'Attendance Status',
      },{
        to:null,
        text: 'Edit Attendance Status',
        active:true             
      }];
      return item;
    },    

  },
  mounted(){
    this.jobTitleDetail();
  }
}
</script>
